import React, { useMemo } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { getFormattedStoreHours } from "@col-care/common/common";
import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";
import * as styles from "../styles/success.module.css";

const CheckoutSuccess = (props) => {
  const state = useSelector((state) => state);

  const locationsByStoreId = useMemo(() => {
    return state.hydrate.databagData.locations
      ? state.hydrate.databagData.locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [state.hydrate.databagData.locations]);

  if (typeof window !== "object") {
    return null;
  }

  let parsed, janeCart;
  parsed = queryString.parse(props.location.search);

  const order = localStorage.getItem("tgs-jane-order-" + parsed.id);
  if (!order) {
    return orderNotFoundContent();
  }

  janeCart = localStorage.getItem("tgs-jane-cart-" + parsed.id);
  const obj = JSON.parse(janeCart);
  const total = JSON.parse(order);

  if (!janeCart) {
    return orderNotFoundContent();
  }

  const location = locationsByStoreId[obj.store.janeId];

  if (!location) {
    return null;
  }

  const formattedStoreHours =
    location && location.content.hours
      ? getFormattedStoreHours(location.content.hours)
      : null;

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const renderDayHours = (day) => {
    const isOpen = formattedStoreHours && formattedStoreHours[`${day}_open`];
    const isClose = formattedStoreHours && formattedStoreHours[`${day}_close`];
    return isOpen && isClose ? (
      <p key={day}>{`${day.charAt(0).toUpperCase() + day.slice(1)}: ${
        formattedStoreHours[`${day}_open`]
      } - ${formattedStoreHours[`${day}_close`]}`}</p>
    ) : (
      <p key={day}>{`${day.charAt(0).toUpperCase() + day.slice(1)}: Closed`}</p>
    );
  };

  return (
    <div id={styles.checkout_container}>
      <div className={styles.success_container}>
        <div className={styles.success_item}>
          <div className={styles.success_item_inner}>
            <div>
              <LazyLoadingImage src="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt3c5cd65c44fffd69/630d820bfe190b157474cb3a/tgs-logo.png" />
            </div>
            <div>
              <h2>Success!</h2>
              <p>Your pre-order has been processed</p>
              <h4>
                ORDER NO:{" "}
                <span className={styles.green_tgs}>{total.cartId}</span>
              </h4>
            </div>
          </div>
          <div className={styles.pickup_info_container}>
            <div className={styles.pickup_info}>
              <h3>PICK UP AT:</h3>
              <div className={styles.tgs_location}>
                <h5>{obj.store.name}</h5>
                <span>{location?.content.address_1}</span>
                <span>
                  {location?.content.city}, CO {location?.content.zip_code}{" "}
                </span>
                <span id={styles.med_and_rec}>RECREATIONAL ONLY</span>
              </div>
              <div className={styles.tgs_hours}>
                <h5>HOURS</h5>
                {daysOfWeek.map((day) => renderDayHours(day))}
              </div>
            </div>
            <div className={styles.pickup_info}>
              <h3 id={styles.your_info}>YOUR INFO:</h3>
              <span>
                {total.customerFirstName} {total.customerLastName}
              </span>
              <span>{total.customerEmail}</span>
              <span>{total.customerPhone}</span>
            </div>
          </div>
        </div>
        <div className={styles.success_item}>
          <h3>Order Summary</h3>
          {obj?.products.map((item, key) => (
            <div className={styles.success_product} key={key}>
              <div>
                <LazyLoadingImage src={item.photo} />
              </div>
              <div>
                <span className={styles.product_brand}>{item.brand}</span>
                <span className={styles.product_kind}>{item.name}</span>
              </div>
              <div>
                {" "}
                <span className={styles.product_amount}>
                  {item.count}x ${item.price.toFixed(2)}
                </span>
                <span className={styles.product_price}>
                  {(item.price * item.count).toFixed(2)}
                </span>
              </div>
            </div>
          ))}
          <div id={styles.price_section}>
            <div className={styles.totals}>
              <div>SUBTOTAL</div>
              <div>${total.estimatedTotal.toFixed(2)}</div>
            </div>
            <div className={styles.totals}>
              <div>EST. TAX</div>
              <div>${total.storeTax}</div>
            </div>

            <div className={styles.totals} id={styles.full_total}>
              <div>YOUR TOTAL</div>
              <div>${(total.estimatedTotal + total.storeTax).toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;

const orderNotFoundContent = () => {
  return (
    <div id={styles.checkout_container}>
      <div className={styles.success_container}>
        <div className={styles.success_item}>
          <div className={styles.success_item_inner}>
            <div>
              <LazyLoadingImage src="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt3c5cd65c44fffd69/630d820bfe190b157474cb3a/tgs-logo.png" />
            </div>
            <div>
              <h2>Sorry!</h2>
              <p>Order not found.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
