// extracted by mini-css-extract-plugin
export var checkout_container = "success-module--checkout_container--b7960";
export var full_total = "success-module--full_total--a25ac";
export var green_tgs = "success-module--green_tgs--9dbec";
export var med_and_rec = "success-module--med_and_rec--17100";
export var pickup_info = "success-module--pickup_info--14a57";
export var pickup_info_container = "success-module--pickup_info_container--45d14";
export var pickup_text = "success-module--pickup_text--d631e";
export var product_amount = "success-module--product_amount--9755d";
export var product_brand = "success-module--product_brand--d361a";
export var product_kind = "success-module--product_kind--436af";
export var product_price = "success-module--product_price--5fba5";
export var success_container = "success-module--success_container--9aec0";
export var success_item = "success-module--success_item--8314f";
export var success_item_inner = "success-module--success_item_inner--f8d87";
export var success_product = "success-module--success_product--8351e";
export var tgs_hours = "success-module--tgs_hours--f8285";
export var totals = "success-module--totals--9ee21";
export var your_info = "success-module--your_info--8c7f1";